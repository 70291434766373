.title {
  margin-bottom: 0.1rem;
  margin-top: 0;
}

.maximizable-container {
  display: flex;
  flex-direction: row;
}

.maximizable-container.fullscreen {
  flex-direction: column-reverse;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 2rem;
}

.maximizable-container.fullscreen .maximizable-content {
  width: 100%;
}

.maximizable-actions {
  width: 5rem;
  margin-left: -4rem;
  margin-top: -1rem;
}

.maximizable-container.fullscreen .maximizable-actions {
  width: auto;
  margin-left: auto;
}

.maximizable-actions button {
  background-color: white;
}

.h1 {
  margin-top: 0.25rem;
  font-size: 1.8rem;
}
